import React, {useEffect, useState, useRef} from 'react'
import './myAdvertisement.css'
import topPriority from '../images/certifiedWinner.png'
import MetricType from '../metricType/metricType'
import CreateAdvertisement from '../createAdvertisement/createAdvertisement'
import UserStatement from '../userStatement/userStatement'
import ConfirmSubmission from '../confirmSubmission/confirmSubmission'
import SuccessSubmission from '../successSubmission/successSubmission'
import ErrorWindow from '../errorWindow/errorWindow'

import mixpanel from 'mixpanel-browser';


import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import RedirectLogout from '../redirectLogout/redirectLogout'

import { dotWave } from 'ldrs'
dotWave.register()



export default function MyAdvertisement({type}) {

    const [districtName, setDistrictName] = useState(null)

    let myInformation = TokenService.getUser()


    mixpanel.init('1dba164ecdda445989be8b3d0b0271cc');

    // STATE RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const [redirectLogout, setRedirectLogout] = useState(false)

    const [myAdvertisement, setMyAdvertisement] = useState({})
    
    const [createStatus, setCreateStatus] = useState('noStatement')

    const [createAdvertisementDisplay, setCreateAdvertisementDisplay] = useState('none')
    const [myAdvertisementDisplay, setMyAdvertisementDisplay] = useState('none')


    const [modifyLoad, setModifyLoad] = useState(false)

    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        setMyAdvertisement({})
        setCreateAdvertisementDisplay('none')
        setMyAdvertisementDisplay('none')
        setCreateStatus('noStatement')
        setErrorDisplay('none')
        setErrorDetails({title: '', description: ''})
        
        // Load my existing advertisement
        getMyAdvertisement()

        for(let i = 0; i < myInformation.districts.length; i++){
            if(myInformation.districts[i].districtType == type){
                setDistrictName(myInformation.districts[i].districtLabel)
            }
        }

    }, [type]);

    // Getting my self created advertisements
    function getMyAdvertisement(){
        
        API.post('getMyAdvertisement', {type: type})
        .then(res => {
            if(res.data.status === 'found'){
                setMyAdvertisementDisplay('none')
                setMyAdvertisement(res.data)
                setCreateStatus('loaded')

            } else {
                setMyAdvertisementDisplay('block')
                setMyAdvertisement({})
            }
        })
        .catch(err => {
            console.log(err)
            if(err === 'Forbidden'){ 
                setRedirectLogout(true)
                return false
            } else {
                setErrorDetails(err)
                setErrorDisplay('block')
                return false
            }
        })


    }

    // Expand advertisement creation
    function openAdvertismentCreation(source){
        setCreateAdvertisementDisplay('block')
    }

    // Collapse advertisement creation
    function closeAdvertismentCreation(){
        setCreateAdvertisementDisplay('none') 
    }

    // Prompt "Are You Sure?"
    async function areYouSureHandler(myData){

        setMyAdvertisement(myData)
        setCreateStatus('finalizing')
    }

    let myMessage = 'Are you sure you want to submit this statement to those in your ' + type.toLowerCase() + ' district? You will only have the ability to vote once per governement district per A&S session. Please choose carefully.'

    let successMessage = 'Your ' + type.toLowerCase()  + ' advertisement has been submitted! Be sure to check in on the status of your statement frequently to view updated stats.' 

    // Upon confirmation, one will either advance or withdraw from finalization
    async function advanceOrWithdraw(direction){
        console.log(direction)

        if(direction === 'advance'){

            let status = await API.post('submitAdvertisement', myAdvertisement)
            .then(res => {
                console.log(res.data)
                setMyAdvertisement(res.data)
                setCreateStatus('success')

                mixpanel.track("New Advertisement");

                return true
            })
            .catch(err => {
                if(err === 'Forbidden'){ 
                    setRedirectLogout(true)
                    return false
                } else {
                    setCreateStatus('started')
                    setErrorDetails(err)
                    setErrorDisplay('block')
                    return false

                }
            })

        } else {
            // Clear the contents !!!
            setMyAdvertisement({})
            setCreateStatus('started')
            
        }
    }

    function closeSuccess(){
        setMyAdvertisementDisplay('none')
        setCreateStatus('loaded')
    }

    // Expand advertisement creation
    async function modifyRenewal(){

        if(modifyLoad){
            return
        }

        setModifyLoad(true)

        let newRenewalState;

        if(myAdvertisement.renewal){
            newRenewalState = false
        } else {
            newRenewalState = true
        }

        let newMyAdvertisementState = myAdvertisement;
        newMyAdvertisementState.renewal = newRenewalState;

        let status = await API.post('modifyRenewal', {
            renewal: newRenewalState,
            type: type
        })
            .then(res => {
                getMyAdvertisement()
                setModifyLoad(false)
                return true
            })
            .catch(err => {
                if(err === 'Forbidden'){ 
                    setRedirectLogout(true)
                    
                    return false
                } else {
                    setCreateStatus('started')
                    setErrorDetails(err)
                    setErrorDisplay('block')
                    return false

                }
            })
        
    }

  return (
    <div className='my_advertisement_background'>
        <RedirectLogout trigger={redirectLogout}></RedirectLogout>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        {/* Error & Finalize*/}
        <ConfirmSubmission display={createStatus === 'finalizing' ? true : false} type={type} close={advanceOrWithdraw} message={myMessage}></ConfirmSubmission>
        <SuccessSubmission display={createStatus === 'success' ? true : false} type={type} message={successMessage} close={closeSuccess}></SuccessSubmission>
        <div style={{paddingTop: '1.5rem'}}>
            {/* No advertisement? */}
            <div style={{display: myAdvertisementDisplay}} className='add_advertisement_outer_container'>

                <div style={{display: createAdvertisementDisplay === 'block' ? 'none' : 'block'}} className='add_advertisement_inner_container' onClick={()=>openAdvertismentCreation('noStatement')}>
                    <i className="fa fa-plus-circle add_icon_big pulse" aria-hidden="true"></i>
                    <div style={{display: type == 'City' ? 'block' : 'none'}} className='add_advertisement_text_big'>What is something you would like to see change here in the city of {districtName}?</div>
                    <div style={{display: type == 'Township' ? 'block' : 'none'}} className='add_advertisement_text_big'>What is something you would like to see change in the township of {districtName}?</div>
                    <div style={{display: type == 'County' ? 'block' : 'none'}} className='add_advertisement_text_big'>What is something you would like to see change in {districtName} county?</div>
                    <div style={{display: type == 'State' ? 'block' : 'none'}} className='add_advertisement_text_big'>What is something you would like to see change here in the state of {districtName}?</div>
                    <div style={{display: type == 'Federal' ? 'block' : 'none'}} className='add_advertisement_text_big'>What is something you would like to see change here in the {districtName}?</div>
                </div>

                {/* Create an advertisement! */}
                <CreateAdvertisement display={createAdvertisementDisplay} type={type} advertisement={myAdvertisement} exitCreation={closeAdvertismentCreation} submitCreation={areYouSureHandler}></CreateAdvertisement>
            </div>
            
            
            {/* Load my advertisement */}
            <div style={{display: createStatus === 'loaded' ? 'block' : 'none'}} className='fade-in-left add_advertisement_outer_container'>
                <div className='my_district_advertisement_title'>My {type} Advertisement</div>
                <UserStatement statementContent={myAdvertisement} session={false} type={false}></UserStatement>
                
                <l-dot-wave style={{float: 'right', marginTop: '-6px', marginRight: '70px', display: modifyLoad ? 'block' : 'none'}} size="45" speed=".65" color="black" ></l-dot-wave>
                <div style={{display: modifyLoad ? 'none' : 'block'}}>
                    <div style={{display: myAdvertisement.renewal === true ? 'none' : 'block'}} className='my_advertisement_edit_creation' onClick={()=>modifyRenewal()}><span style={{fontSize: '18pt'}}>&#8853;</span> Add Auto-Renewal</div>
                    <div style={{display: myAdvertisement.renewal === false ? 'none' : 'block'}} className='my_advertisement_edit_creation' onClick={()=>modifyRenewal()}><span style={{fontSize: '18pt'}}>&#8861;</span> Remove Auto-Renewal</div>
                </div>
                
            </div>
        </div>
    </div>
    
  )
}
