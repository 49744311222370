import React from 'react'
import './successSubmission.css'
import success from '../images/successfullySubmitted.png'

export default function SuccessSubmission({display, message, close}) {



    function closeHandler(){
        close()
    }

    return (
        <div style={{display: display ? 'block' : 'none'}} className='success_submission_overlay'>
            <div className='success_submission_section'>
                <img className='success_submission_icon_center' src={success}></img>
                <div className='success_submission_description'>{message}</div>
                <div className='common_button common_ack_button' onClick={()=>closeHandler()}>Okay</div>
            </div>
        </div>
    )
}
