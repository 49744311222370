import React from 'react'
import './metricDescription.css'
import informationIcon from '../images/informationIcon.png'

import agree from '../images/agree.png'
import disagree from '../images/disagree.png'
import priority from '../images/priority.png'
import verified from '../images/verified.png'
import unverified from '../images/unverified.png'

export default function MetricDescription({display, type, count, closeDescription}) {

    function closeDescriptionHandler(){
        closeDescription()
    }

  return (
    <div style={{display: display}} className='common_overlay'>
        <div className='error_window_section'>
            
           


            <div className='metric_description_count' style={{marginTop: '2rem', marginBottom: '1rem'}}>{count}</div>
            <div style={{display: type === 'RPV' ? 'block' : 'none'}} className='metric_description_title'>Registered Priority Votes</div>
            <div style={{display: type === 'UPV' ? 'block' : 'none'}} className='metric_description_title'>Unregistered Priority Votes</div>
            <div style={{display: type === 'RAV' ? 'block' : 'none'}} className='metric_description_title'>Registered Agree Votes</div>
            <div style={{display: type === 'UAV' ? 'block' : 'none'}} className='metric_description_title'>Unregistered Agree Votes</div>
            <div style={{display: type === 'RDV' ? 'block' : 'none'}} className='metric_description_title'>Registered Disagree Votes</div>
            <div style={{display: type === 'UDV' ? 'block' : 'none'}} className='metric_description_title'>Unregistered Disagree Votes</div>









            <div style={{display: type === 'RPV' ? 'block' : 'none'}} className='metric_description_description'>These votes indicate the number of registered voters who participated in the election of this prioritized statement.</div>
            <div style={{display: type === 'UPV' ? 'block' : 'none'}} className='metric_description_description'>These votes indicate the number of unregistered voters who participated in the election of this prioritized statement.</div>
            <div style={{display: type === 'RAV' ? 'block' : 'none'}} className='metric_description_description'>These votes indicate the number of registered voters who agree with this this statement.</div>
            <div style={{display: type === 'UAV' ? 'block' : 'none'}} className='metric_description_description'>These votes indicate the number of unregistered voters who agree with this this statement.</div>
            <div style={{display: type === 'RDV' ? 'block' : 'none'}} className='metric_description_description'>These votes indicate the number of registered voters who disagree with this statement.</div>
            <div style={{display: type === 'UDV' ? 'block' : 'none'}} className='metric_description_description'>These votes indicate the number of unregistered voters who disagree this statement.</div>


            <div className='common_button common_ack_button' onClick={()=>closeDescriptionHandler()}>Okay</div>
        </div>
    </div>
  )
}
