import React, {useEffect, useRef, useState} from 'react'
import '../../common.css'
import addLocation from '../../images/addLocation.png'
import ErrorWindow from '../../errorWindow/errorWindow'
import Select from 'react-select'
import ProgressBar from '../../progressBar/progressBar'
import './addressValidate.css'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import SearchAutocomplete from '../../utilities/autocomplete'

import AddressParser from 'parse-address'



export default function AddressValidate({display, setAddress, setRepresentatives, slide}) {

    var baseAddress = null

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    baseAddress = 'https://voterstack.com:5016/'
    } else {
    // production code
    baseAddress = 'https://voterstack.com:5015/'
    }


    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])


    const [localAddressVariables, setLocalAddressVariables] = useState({addressLineOne: '', addressLineTwo: '', city: '', state: '', zipCode: ''})

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
  

    const addressLineTwo = useRef()


    //////////////////////////////////////////////////////////////
    // setRepresentativesHandler
    //////////////////////////////////////////////////////////////
    function setRepresentativesHandler(representatives){
        setRepresentatives(representatives)
    }

    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('addressValidate', direction)
    }

    //////////////////////////////////////////////////////////////
    // validateAddress
    //////////////////////////////////////////////////////////////
    async function validateCoverage(){

        await fetch(baseAddress + "validateCoverage", {
            method: "POST",
            headers: {
            "content-type" : 'application/json'
            },
            body: JSON.stringify({
                addressLineOne: localAddressVariables.addressLineOne,
                addressLineTwo: addressLineTwo.current.value,
                city: localAddressVariables.city,
                state: localAddressVariables.state,
                zipCode: localAddressVariables.zipCode,
            })
        })
        .then(res => {
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        })
        .then(res => {
            setAddress({
                addressLineOne: localAddressVariables.addressLineOne,
                addressLineTwo: addressLineTwo.current.value,
                city: localAddressVariables.city,
                state: localAddressVariables.state,
                zipCode: localAddressVariables.zipCode
            })
            // Set representatives for preview
            setRepresentativesHandler(res)
            // Go to preview coverage
            slideHandler(true)
        })
        .catch(error => {
            setErrorDetails(error)
            setErrorDisplay('block')
        })

    }


   
    function parseAddressForRadar(formattedAddress){
        // Remove US
        formattedAddress = formattedAddress.slice(0, -3);

        // Get zip
        let zipCode = formattedAddress.substr(formattedAddress.length - 5);

        // Remove zipCode
        formattedAddress = formattedAddress.slice(0, -6);

        // get state
        let state = formattedAddress.substr(formattedAddress.length - 2);

        //remove state
        formattedAddress = formattedAddress.slice(0, -4);

        const lineOneAndCity = formattedAddress.split(', ');

        return {
            addressLineOne: lineOneAndCity[0],
            city: lineOneAndCity[1],
            state: state,
            zipCode: zipCode
        }
    }


    function addLineOneAddress(address){
        let tempObject = localAddressVariables
        tempObject.addressLineOne = address.addressLineOne
        tempObject.city = address.city
        tempObject.state = address.state
        tempObject.zipCode = address.zipCode
        setLocalAddressVariables(tempObject)
    }




  return (
    <div style={{display: display ? 'block' : 'none'}}>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        
        <img className='common_icon_center' src={addLocation}></img>
        <div className='common_title_subtext'>Welcome To VoterStack!<br></br> Let's start by validating your coverage!</div>
        <ProgressBar page='addressValidate'></ProgressBar>
        <div className='common_note'>Please provide your address of residency in the form below. This will determine your correct officials for each of the districts in which you reside. You will be required to provide proof of this information in a prompt to follow.</div>
        <div className='common_note'>Please note: Only specific residents will be eligible to become a member of VoterStack at this time pending the current capacity of our location coverage.</div>
        
        <SearchAutocomplete setAddress={addLineOneAddress}></SearchAutocomplete>


        <input ref={addressLineTwo} className='address_validate_line_two_entry' placeholder="Apartment or Suite #" ></input>

        
        
    
        <div className='common_button_combination' style={{marginTop: '3rem'}}>
            <div style={{marginRight: '5px'}} className='common_button common_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>
            <div style={{marginLeft: '5px'}} className='common_button common_submit_button' onClick={()=>validateCoverage()}>Next</div>
        </div>
    </div>
  )
}
