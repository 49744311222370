import React, {useState, useEffect} from 'react'
import './nonResponsive.css'

import PhoneContact from '../images/phoneContact.png'
import EmailContact from '../images/emailContact.png'


export default function NonResponsive({display, data, closeWindow}) {

    let curDate = new Date()
    curDate = curDate.getTime()


    let newDate = curDate - data.lastActiveEpoch
    newDate = Math.floor(newDate / 1000)
    let totalHours = newDate / 3600
    let totalDays = totalHours / 24

    totalDays = Math.round(totalDays)

    




    function closeWindowHandler(){
        closeWindow()
    }

  return (
    <div style={{display: display}} className='common_overlay'>
        <div className='non_responsive_section'>
            <img className='non_responsive_profile_center' src={data.profilePictureURL}></img>
            <div className='non_responsive_name'>{data.officialTitle + " " + data.firstName + " " + data.lastName}</div>
            <div className='non_responsive_description' style={{display: data.firstTime ? 'block' : 'none'}}>{data.firstName} has recieved an invitation to VoterStack as well as a copy of this report.<br></br><br></br> They have not responded in <span style={{fontWeight: 'bold'}}>{totalDays}</span> days. </div>
            <div className='non_responsive_description' style={{display: data.firstTime ? 'none' : 'block'}}>{data.firstName} was last active <span style={{fontWeight: 'bold'}}>{totalDays}</span> days ago</div>

            <div className='non_responsive_would_you_contact'>Would you like to contact your {data.districtType} {data.officialTitle}?</div>
            <hr></hr>
            <div className='non_responsive_image_title_grid' style={{marginTop: '25px'}}>
                
              <img className='non_responsive_contact_image' src={PhoneContact}></img>
              <div className='non_responsive_contact_label'>({data.firstSet}) {data.midSet}-{data.lastSet}</div>
            </div>
            <div></div>
            <div className='non_responsive_image_title_grid'>
              <img className='non_responsive_contact_image' src={EmailContact}></img>
              <div className='non_responsive_contact_label' style={{marginBottom: '25px'}}>{data.officeEmailAddress}</div>
            </div>
            <div className='common_button common_ack_button' onClick={()=>closeWindowHandler()}>Return</div>
        </div>
    </div>
  )
}
