import React, {useEffect, useState, useRef} from 'react'
import './userSettings.css'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"


import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import Header from '../header/header'
import Navigation from '../navigation/navigation'

import ContactSupport from './contactSupport/contactSupport.js'

import settingsImage from '../images/gear.png'

import SuccessSubmission from '../successSubmission/successSubmission'


import VoterRegistration from './voterRegistration/voterRegistration.js'
//import ChangeOfInformation from './changeOfInformation/changeOfInformation.js'
import Unsubscribe from './unsubscribe/unsubscribe.js'

export default function UserSettings() {

  const navigate = useNavigate()
  const [queryParameters] = useSearchParams()

  useEffect(() => {

    // Load request state
    getMyRequests()
    window.scrollTo(0, 0)

  }, [])

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const [errorDisplay, setErrorDisplay] = useState('none')
  const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

  function closeError(){
      setErrorDisplay('none')
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // NAVIGATION RELATED ///////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [pageContentsDisplay, setPageContentsDisplay] = useState('block')
  const [navigationDisplay, setNavigationDisplay] = useState('none')

  function hidePage(){
      setPageContentsDisplay('none')
      setNavigationDisplay('block')
  }

  function displayPage(){
      setPageContentsDisplay('block')
      setNavigationDisplay('none')
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////////////////////////////////////////////
  // CUR REQUESTS  ///////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  async function getMyRequests(){
    
    await API.post('getMyRequests', {})
    .then(res => {
        setOptionReturn(res.data)
    })
    .catch(err => { 
        console.log(err)
        setErrorDetails(err)
        setErrorDisplay('block')
        return false
    }) 
  }

  /////////////////////////////////////////////////////////////////////////////////////////////




  /////////////////////////////////////////////////////////////////////////////////////
  // Submit Registration Verification
  /////////////////////////////////////////////////////////////////////////////////////
  async function submitRegistrationVerification(direction){

    if(direction){
      await API.post('setRequest', {
        requestType: 'registrationVerification'
      })
        .then(async res => {
          closeRequestWindow()
          // Confirm
          await getMyRequests()
          setSuccessSubmissionMessage("Your request for voter registration has been successfully submitted. You will receive a status update when we have completed our processing. Thank you for your patience!")
          setSuccessSubmissionDisplay(true)
        })
        .catch(err => { 
            console.log(err)
            setErrorDetails(err)
            setErrorDisplay('block')
            return false
        }) 

    } else {
      closeRequestWindow()
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // Submit Delete Account
  /////////////////////////////////////////////////////////////////////////////////////
  async function submitDeleteAccount(direction, password){

    console.log(password)

    if(direction){
      await API.post('setUnsubscribeAndDelete', {
        password: password
      })
        .then(async res => {
          setSuccessSubmissionMessage("Your account has been successfully deleted! You will now be signed out and redirected to the home screen.")
          setSuccessSubmissionDisplay(true)
          // Token session delete
        })
        .catch(err => { 
          console.log(err)
          setErrorDetails(err)
          setErrorDisplay('block')
          return false
        }) 

    } else {
      closeRequestWindow()
    }
  }



  /////////////////////////////////////////////////////////////////////////////////////
  // 
  /////////////////////////////////////////////////////////////////////////////////////
  const [successSubmissionDisplay, setSuccessSubmissionDisplay] = useState(false)
  const [successSubmissionMessage, setSuccessSubmissionMessage] = useState('')
  const [settingDisplay, setSettingDisplay] = useState('none')
  const [optionReturn, setOptionReturn] = useState([])
  const scrollReference = useRef()

  function closeSuccessSubmission(){
    setSuccessSubmissionDisplay(false)
    TokenService.removeUser()
    navigate('/')
  }

  function closeRequestWindow(){
    setSettingDisplay('none')
    scrollReference.current.scrollTop = 0
  }



  return (
    <div className='screen_background' ref={scrollReference}>
        <Header hideFunction={hidePage} openFunction={displayPage} navDisplay={navigationDisplay}></Header>
        <Navigation navigationDisplay={navigationDisplay} type={queryParameters.get("type")}></Navigation>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>

        <SuccessSubmission display={successSubmissionDisplay} message={successSubmissionMessage} close={closeSuccessSubmission}></SuccessSubmission>


        <div className='user_settings_screen_foreground' style={{display: pageContentsDisplay}} >

            {/* Main */}
            <div className='user_settings_section' style={{display: settingDisplay === 'none' ? 'block' : 'none'}}>
              <div className='user_settings_title_grid'>
                <img className='user_settings_title_image' src={settingsImage}></img>
                <div className='user_settings_section_title'>Settings</div>
              </div>
              <hr style={{marginBottom: '20px'}}></hr>

              {/*<div className='user_settings_selection' onClick={()=>setSettingDisplay('changeInformation')}>Request Change Of Information</div>*/}

              { optionReturn.includes('registrationVerification') ?
              null : <div className='user_settings_selection' onClick={()=>setSettingDisplay('registeredVoterVerify')}>Request Voter Registration Verification</div>
              }


              <div className='user_settings_selection' style={{color: 'red'}} onClick={()=>setSettingDisplay('unsubscribe')}>Unsubscribe & Close Account</div>

              
            </div>


            {/* Voter Registration */}
            <VoterRegistration display={settingDisplay === 'registeredVoterVerify' ? 'block' : 'none'} closeWindow={submitRegistrationVerification}></VoterRegistration>

            {/* Change Of Information */}
            {/*<ChangeOfInformation display={settingDisplay === 'changeInformation' ? 'block' : 'none'} closeWindow={closeRequestWindow}></ChangeOfInformation>*/}

            {/* Unsubscribe */}
            <Unsubscribe display={settingDisplay === 'unsubscribe' ? 'block' : 'none'} closeWindow={submitDeleteAccount}></Unsubscribe>

            {/* Success Page */}






        </div>
    </div>
  )
}
