import React, {useEffect, useState, useRef} from 'react'
import ErrorWindow from '../../errorWindow/errorWindow'
import HouseIcon from '../../images/houseIcon.png'
import ExampleID from '../../images/exampleID.jpg'
import './mailProof.css'
import ProgressBar from '../../progressBar/progressBar'

export default function MailProof({display, setMailProof, slide}) {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    // Image selection
    const hiddenFileInput = React.useRef(null);

    const handleClickRedirect = event => {
        hiddenFileInput.current.click();
    };

    let [mail, setMail] = useState()
    let [mailPreview, setMailPreview] = useState()
    let [isFile, setIsFile] = useState(false)

    //////////////////////////////////////////////////////////////
    // handleMailCollect
    //////////////////////////////////////////////////////////////
    function handleMailCollect(){
        setIsFile(true)
        setMail(hiddenFileInput.current.files[0])
        setMailPreview(URL.createObjectURL(hiddenFileInput.current.files[0]))
    }

    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('mailProof', direction)
    }

    //////////////////////////////////////////////////////////////
    // addIDPhoto
    //////////////////////////////////////////////////////////////
    function addMailPhoto(){

        if(!isFile){
            setErrorDetails({title: "Residential Address Photo missing", description: "Please use the upload button to upload your utility bill or lease agreement."})
            setErrorDisplay('block')
            setIsFile(false)
            return
        }

        setMailProof(hiddenFileInput.current.files[0])
        slide('mailProof', true)
    }

    return (
        <div style={{display: display ? 'block' : 'none'}}>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
            <img className='common_icon_center' src={HouseIcon}></img>
            <div className='common_title_subtext'>Now let's validate your residence!</div>
            <ProgressBar page='verifyLocation'></ProgressBar>
            <div className='common_note'>Now we need to collect your proof of residency via either a utility bill or lease agreement which displays the address in which you reside.</div>

            <div className='common_note' style={{marginTop: '1rem'}}>Please capture and upload a clear photo of your utility bill or lease agreement which displays the address with the corners of the paper included. Once you have finished selecting, please continue to the review screen.</div>
            
            {/* Image preview */}
            <img style={{display: isFile ? "block" : "none"}}  className='identity_proof_id_preview' src={mailPreview} />


            {/* Select image */}
            <div onClick={handleClickRedirect} className='common_button common_submit_button' style={{marginTop: '2rem', marginBottom: '2rem'}}>
            {isFile ? "Change Address Photo" : "Upload Address Photo"}
            </div>
            <input type="file"
             ref={hiddenFileInput}
             onChange={()=>handleMailCollect()}
             style={{display:'none'}} 
            /> 


            <div className='common_button_combination' >
                <div style={{marginRight: '5px'}} className='common_button common_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>
                <div style={{marginLeft: '5px'}} className='common_button common_submit_button' onClick={()=>addMailPhoto()}>Review</div>
            </div>
        </div>
    )
}