import React from 'react'
import './representativesBox.css'

export default function RepresentativesBox({representative}) {
  return (
    <div className='representatives_box_section'>
        <img className='representatives_box_photo_section' src={representative.photoURL}></img>
        <div>
            <span className='representatives_box_name'>{representative.name}</span><br></br>
            <span className='representatives_box_title'>{representative.title}</span><br></br>
            <span className='representatives_box_branch'>{representative.branch}</span><br></br>
            <span className='representatives_box_branch'>{representative.term}</span><br></br>
        </div>
    </div>
  )
}
