import React, {useEffect} from 'react'
import './advertisementFilter.css'

export default function AdvertisementFilter({display, filter, closeWindow}) {

  function selectionHandler(selection){
    closeWindow(selection)
  }



  return (
    <div style={{display: display}} className='advertisement_filter_overlay'>
      <div className='advertisement_filter_options_center'>
        <div className='advertisement_filter_title' style={{marginBottom: '3rem'}}>Filter Results</div>
        <div className={filter == "HCPV" ? 'advertisement_filter_highlight' : 'advertisement_filter_element'} onClick={()=>selectionHandler('HCPV')}>Highest Cumulative Priority Votes</div>
        <div className={filter == "LCPV" ? 'advertisement_filter_highlight' : 'advertisement_filter_element'} onClick={()=>selectionHandler('LCPV')}>Lowest Cumulative Priority Votes</div>
        <div className={filter == "HRPV" ? 'advertisement_filter_highlight' : 'advertisement_filter_element'} onClick={()=>selectionHandler('HRPV')}>Highest Registered Priority Votes</div>
        <div className={filter == "LRPV" ? 'advertisement_filter_highlight' : 'advertisement_filter_element'} onClick={()=>selectionHandler('LRPV')}>Lowest Registered Priority Votes</div>
        <div className={filter == "HUPV" ? 'advertisement_filter_highlight' : 'advertisement_filter_element'} onClick={()=>selectionHandler('HUPV')}>Highest Unregistered Priority Votes</div>
        <div className={filter == "LUPV" ? 'advertisement_filter_highlight' : 'advertisement_filter_element'} onClick={()=>selectionHandler('LUPV')}>Lowest Unregistered Priority Votes</div>


        <div className='advertisement_filter_element advertisement_filter_element_cancel' onClick={()=>selectionHandler('cancel')}>Cancel</div>
      </div>

    </div>
  )
}
