import React, {useState} from 'react'
import './passwordReset.css'
import { useNavigate, useLocation } from 'react-router-dom';
import passwordResetImage from '../images/passwordReset.png'

import ProvideProof from './provideProof/provideProof'
import VerifyCode from './verifyCode/verifyCode'
import ResetStatus from './resetStatus/resetStatus'

import success from '../images/success.png'

export default function PasswordReset() {
    const navigate = useNavigate()

    const [progressionStatus, setProgressionStatus] = useState('provideProof')
    const [passwordChangeData, setPasswordChangeData] = useState({})


    function advanceOrWithdraw(page, position){
        console.log(page)

        switch(page){
            case 'provideProof':
                if(position){
                    setProgressionStatus('verifyCode')
                } else {
                    navigate('/')
                }
            break;

            case 'verifyCode':
                if(position){
                    setProgressionStatus('resetStatus')
                } else {
                    setProgressionStatus('provideProof')
                }
            break;
        }

    }

    function addPasswordChangeData(data){
        setPasswordChangeData(data)
        console.log(data)
    }




  return (
    <div className='screen_background background_image'>
        
        <div className='password_reset_screen_foreground'>
            <div className='password_reset_section'>
                <div style={{margin: '0 auto', display: 'block', textAlign: 'center'}}>
                    <img className='password_reset_title_image' src={progressionStatus == 'resetStatus' ? success : passwordResetImage}></img>
                </div>
                <div className='password_reset_title_label'>{progressionStatus == 'resetStatus' ? "Password Successfully Changed!" : "Password Reset"}</div>

                <ProvideProof progressionState={progressionStatus} advanceOrWithdraw={advanceOrWithdraw} setData={addPasswordChangeData}></ProvideProof>

                <VerifyCode progressionState={progressionStatus} advanceOrWithdraw={advanceOrWithdraw} passwordData={passwordChangeData}></VerifyCode>

                <ResetStatus progressionState={progressionStatus} passwordData={passwordChangeData}></ResetStatus>

            </div>
        </div>
        
    </div>
  )
}
