import React, {useRef, useEffect, useState} from 'react'
import './debateChoose.css'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"

import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import Header from '../header/header'
import Navigation from '../navigation/navigation'
import UserStatement from '../userStatement/userStatement'
import UserArgument from '../userArgument/userArgument'
import ConfirmSubmission from '../confirmSubmission/confirmSubmission'
import SuccessSubmission from '../successSubmission/successSubmission'
import ArgumentFilter from '../argumentFilter/argumentFilter'

import { dotWave } from 'ldrs'
import notSure from '../images/notSure.png'
import agree from '../images/agree.png'
import disagree from '../images/disagree.png'

import mixpanel from 'mixpanel-browser';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    var websiteURL = "https://voterstack.com:3000/debateChoose?"
} else {
    // production code
    var websiteURL = "https://voterstack.com/debateChoose?"
}


export default function DebateChoose() {
    const navigate = useNavigate()

    mixpanel.init('1dba164ecdda445989be8b3d0b0271cc');

    const [queryParameters] = useSearchParams()

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }


    /* redirect error */

    const [redirectErrorDisplay, setRedirectErrorDisplay] = useState('none')
    const [redirectErrorDetails, setRedirectErrorDetails] = useState({title: '', description: ''})

    function closeErrorAndRedirect(){
        navigate('/officialResponses')
        setErrorDisplay('none')
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // NAVIGATION RELATED ///////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [pageContentsDisplay, setPageContentsDisplay] = useState('block')
    const [navigationDisplay, setNavigationDisplay] = useState('none')

    function hidePage(){
        setPageContentsDisplay('none')
        setNavigationDisplay('block')
    }

    function displayPage(){
        setPageContentsDisplay('block')
        setNavigationDisplay('none')
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////
    // SCROLLING RELATED ////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [scrollPosition, setScrollPosition ] = useState(0)
    const scrollReference = useRef()

    function updateScroll(){

        if(scrollReference.current.scrollTop + 1 >= (scrollReference.current.scrollHeight - scrollReference.current.clientHeight) && debateState === 'done'){
            getConstituentArguments(queryParameters.get("type"), queryParameters.get("filter"), skipCount)
        }

        setScrollPosition({y: scrollReference.current.scrollTop, yMax: (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)})
    }
    //=============================================================================================

    // State Variables
    const supportButton = useRef()
    const opposeButton = useRef()
    const myArgumentReference = useRef()

    const [priorityContent, setPriorityContent] = useState({})

    const [userChoice, setUserChoice] = useState(null)
    const [debateState, setDebateState] = useState(null)

    const [skipCount, setSkipCount] = useState(0)
    const [argumentLoad, setArgumentLoad] = useState(false)
    const [memberArguments, setMemberArguments] = useState([])

    const [myArgument, setMyArgument] = useState({status: null, data: {}})
    const [chosenArgument, setChosenArgument] = useState({status: null, data: {}})

    const [chooseState, setChooseState] = useState(null)
    const [chooseID, setChooseID] = useState(null)

    const [characterCount, setCharacterCount] = useState(0)

    const [filterDisplay, setFilterDisplay] = useState('none')

    // First Render
    useEffect(() => {
        resetAll()
        getConstituentArguments(queryParameters.get("type"), queryParameters.get("filter"), 0)
        getConstituentPriority(queryParameters.get("type"));
        getChosenArgument(queryParameters.get("type"))
        getMyArgument(queryParameters.get("type"))
    }, [queryParameters.get("filter"), queryParameters.get("type")]);


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Reset All
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function resetAll(){
        setMemberArguments([]);
        setMyArgument({status: null, data: {}})
        setChosenArgument({status: null, data: {}})
        setDebateState(null)
        setSkipCount(0)
        displayPage()
        setUserChoice(null)
        supportButton.current.className = 'debate_choose_button'
        opposeButton.current.className = 'debate_choose_button'
        myArgumentReference.current.value = 'Start typing here...'
        window.scrollTo(0, 0)
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GetConstituentArguments
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getConstituentArguments(type, filter, skip){

        if(argumentLoad){
            return
        }

        setArgumentLoad(true)

        let argumentRequest = {
            skipCount: skip,
            type: type,
            filter: filter
        }

        await API.post('getConstituentArguments', argumentRequest)
        .then(res => {
            console.log(res.data)

            if(res.data.submitSupport){
                setDebateState('done')
                setMemberArguments(prevState => [...prevState, ...res.data.arguments]);
                setSkipCount(res.data.skipCount)
            } else {
                setDebateState('open')
            }

            setTimeout(() => {
                setArgumentLoad(false)
            }, 1000);


        })
        .catch(err => { 
            console.log(err)
            setErrorDetails(err)
            setErrorDisplay('block')
        })
    }

    

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GetConstituentPriority
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getConstituentPriority(type){

        let priorityRequest = {
            type: type
        }

        await API.post('getConstituentPriority', priorityRequest)
        .then(res => {
            console.log(res.data)
            setPriorityContent(res.data)
        })
        .catch(err => {
            console.log(err)
            setRedirectErrorDetails(err)
            setRedirectErrorDisplay('block')
        })
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HandleChoiceSelection
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function handleChoiceSelection(choice){
        // Deselect
        if(choice === 'support' && userChoice === 'support'){
            supportButton.current.className = 'debate_choose_button'
            setUserChoice(null)
        }
        if(choice === 'support' && userChoice === 'oppose'){
            opposeButton.current.className = 'debate_choose_button'
            supportButton.current.className = 'debate_choose_button debate_choose_button_agree bounce2'
            setUserChoice('support')
        }
        if(choice === 'support' && userChoice === null){
            supportButton.current.className = 'debate_choose_button debate_choose_button_agree bounce2 '
            setUserChoice('support')
        }


        // Deselect
        if(choice === 'oppose' && userChoice === 'oppose'){
            opposeButton.current.className = 'debate_choose_button'
            setUserChoice(null)
        }
        if(choice === 'oppose' && userChoice === 'support'){
            supportButton.current.className = 'debate_choose_button'
            opposeButton.current.className = 'debate_choose_button debate_choose_button_disagree bounce2 '
            setUserChoice('oppose')
        }
        if(choice === 'oppose' && userChoice === null){
            opposeButton.current.className = 'debate_choose_button debate_choose_button_disagree bounce2 '
            setUserChoice('oppose')
        }

        window.scrollTo({
            top: 150,
            behavior: "smooth"
            });

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // SetArgument
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function setArgument(){

        setDebateState('pending')
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // SetChoose
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function setChoose(id){
        setChooseState('pending')
        console.log(id)
        setChooseID(id)
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // AdvanceOrWithdrawDebate
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function advanceOrWithdrawDebate(direction){

        if(direction === 'advance'){
            // API request to submit the submission
            await API.post('setArgument', {
                argument: myArgumentReference.current.value,
                userChoice: userChoice,
                type: queryParameters.get("type")
            })
            .then(res => {
                console.log(res.data)

                mixpanel.track("Position And Debate");

                let updatedTopVote = priorityContent
                let existingVoteType = updatedTopVote[res.data.validatedVote]
                existingVoteType = existingVoteType.toString()
                existingVoteType = existingVoteType.replace(",",'')
                
                updatedTopVote[res.data.validatedVote] = existingVoteType
                updatedTopVote[res.data.validatedVote] = parseInt(updatedTopVote[res.data.validatedVote])
                updatedTopVote[res.data.validatedVote] = updatedTopVote[res.data.validatedVote] + 1
                updatedTopVote[res.data.validatedVote].toLocaleString()

                setPriorityContent(updatedTopVote)
                setDebateState('success')
                
            })
            .catch(err => {
                console.log(err)
                setErrorDetails(err)
                setErrorDisplay('block')
            })
            
        } else {
            // Clear the contents !!!
            setDebateState('open')
        }
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // AdvanceOrWithdrawChoose
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function advanceOrWithdrawChoose(direction){

        if(direction === 'advance'){   
            
            // API request to submit the submission
            await API.post('setChosenArgument', {
                argumentID: chooseID,
                type: queryParameters.get("type")
            })
            .then(res => {
                console.log(res.data)

                mixpanel.track("Argument Chosen");

                let myArray = []

                let existingArguments = memberArguments.slice()

                // Cycle through each iteration and remove the voting button
                
                for(let i = 0; i < existingArguments.length; i++){

                    if(existingArguments[i]._id === chooseID){
                        
                        let existingChosenArgument = existingArguments[i]
                        existingChosenArgument.available = false

                        console.log(existingChosenArgument)

                        existingChosenArgument[res.data.validatedVote] = existingChosenArgument[res.data.validatedVote].replace(",",'')
                        existingChosenArgument[res.data.validatedVote] = parseInt(existingChosenArgument[res.data.validatedVote])
                        existingChosenArgument[res.data.validatedVote] = existingChosenArgument[res.data.validatedVote] + 1
                        existingChosenArgument[res.data.validatedVote].toLocaleString()

                        setChosenArgument({status: 'ok', data: existingChosenArgument})
                    } else {
                        existingArguments[i].available = false
                        myArray.push(existingArguments[i])
                    }
                }


                setMemberArguments(myArray) 
                setChooseState('success')
                
            })
            .catch(err => { 
                console.log(err)
                setErrorDetails(err)
                setErrorDisplay('block')
            }) 
            
        } else {
            // Clear the contents !!!
            setChooseState('open')
            setChooseID(null)
        }
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GetChosenArgument
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getChosenArgument(type){
        // API request to submit the submission
        await API.post('getChosenArgument', {
            type: type
        })
        .then(res => {
            console.log(res.data)

            if(res.data.status == 'none'){
                return
            } else {
                setChosenArgument(res.data)
            }
            
        })
        .catch(err => { 
            console.log(err)
            setErrorDetails(err)
            setErrorDisplay('block')
        })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GetMyArgument
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getMyArgument(type){


        // API request to submit the submission
        await API.post('getMyArgument', {
            type: type
        })
        .then(res => {
            console.log(res.data)

            if(res.data.status == 'none'){
                return
            } else {
                setMyArgument(res.data)
            }
            
        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
        })
            

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // CloseDebateSuccess
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function closeDebateSuccess(){
        setDebateState('done')
        getConstituentArguments(queryParameters.get("type"), queryParameters.get("filter"), skipCount)
        getMyArgument(queryParameters.get("type"))
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // CloseChooseSuccess
    //
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function closeChooseSuccess(){
        setChooseState('done')
        //getChosenArgument(queryParameters.get("type"))
    }

    let submitDebate = 'You can vote and supply your optional argument only once per D&C session. Please be sure before submission.'
    let successDebateMessage = 'Thank you! Your vote and argument has been successfully submitted!'

    let submitChoose = 'You can vote on a single argument only once per D&C session. Please be sure before submission.'
    let successChooseMessage = 'Thank you! Your support for this argument has been successfully submitted!'




    async function makeArgumentSelection(id){
        console.log("Test")
        console.log(id)
    }

    function updateCharacterCount(){
        let curString = myArgumentReference.current.value
        setCharacterCount(curString.length)
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle focus
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function handleFocusTextArea(focused){

        if(myArgumentReference.current.value == 'Start typing here...' && focused){
            myArgumentReference.current.value = ''
        }

        else if(myArgumentReference.current.value == '' && !focused){
            myArgumentReference.current.value = 'Start typing here...'
        }
        
    }


    function openFilterWindow(){
        setFilterDisplay('block')
    }

    function closeFilterWindow(localFilter){
        
        setFilterDisplay('none')

        if(localFilter === 'cancel'){
            return
        }


        // Update the webpage
        navigate('/debateChoose?type=' + queryParameters.get("type") + '&filter=' + localFilter)
    }

    // Open advertise

    return (
        <div className='screen_background' onScroll={updateScroll} ref={scrollReference}>
            <Header hideFunction={hidePage} openFunction={displayPage} navDisplay={navigationDisplay}></Header>
            <Navigation navigationDisplay={navigationDisplay} type={queryParameters.get("type")}></Navigation>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>

            <ErrorWindow display={redirectErrorDisplay} details={redirectErrorDetails} closeError={closeErrorAndRedirect}></ErrorWindow>

            {/* Debate Confirmation */}
            <ConfirmSubmission display={debateState == 'pending' ? true : false} type={queryParameters.get("type")} message={submitDebate} close={advanceOrWithdrawDebate}></ConfirmSubmission>
            <SuccessSubmission display={debateState == 'success' ? true : false} type={queryParameters.get("type")} message={successDebateMessage} close={closeDebateSuccess}></SuccessSubmission>

            {/* Choose Confirmation */}
            <ConfirmSubmission display={chooseState == 'pending' ? true : false} type={queryParameters.get("type")} message={submitChoose} close={advanceOrWithdrawChoose}></ConfirmSubmission>
            <SuccessSubmission display={chooseState == 'success' ? true : false} type={queryParameters.get("type")} message={successChooseMessage} close={closeChooseSuccess}></SuccessSubmission>

            <ArgumentFilter display={filterDisplay} filter={queryParameters.get("filter")} closeWindow={closeFilterWindow}></ArgumentFilter>

        <div style={{display: pageContentsDisplay}} className='debate_choose_screen_foreground'>

            {/* Filter  */}
            <div className='debate_choose_filter_container_bar' style={{display: debateState === 'done' ? 'grid' : 'none'}}>
                <div></div>
                <div>
                    <i class="fa fa-filter debate_choose_filter_button" onClick={()=>openFilterWindow()}></i>
                </div>
            </div> 

            {/* Top chosen item */}

            <div style={{marginTop: '2rem'}}></div>
            <UserStatement statementContent={priorityContent} type={queryParameters.get("type")} session={'debateChoose'}></UserStatement>
        

            

            {/* Do you support/oppose? */}
            <div style={{display: debateState == 'open' ? 'block' : 'none'}}>
                <div  className='debate_choose_section'>
                    <div className='debate_choose_choice_image_center'>
                        <img style={{display: userChoice == null ? 'block' : 'none'}} src={notSure} className='debate_choose_choice_image'/>
                        <img style={{display: userChoice == 'support' ? 'block' : 'none'}} src={agree} className='debate_choose_choice_image'/>
                        <img style={{display: userChoice == 'oppose' ? 'block' : 'none'}} src={disagree} className='debate_choose_choice_image'/>
                    </div>
                    <div className='debate_choose_do_you_support_text'>Do you support or oppose the above statement?</div>
                    <div className='debate_choose_button_container'>
                        <div ref={supportButton} style={{marginRight: '.5rem'}} className='debate_choose_button' onClick={()=>handleChoiceSelection('support')}>I support</div>
                        <div ref={opposeButton} style={{marginLeft: '.5rem'}} className='debate_choose_button' onClick={()=>handleChoiceSelection('oppose')}>I oppose</div>
                    </div>
                </div>

                {/* Why do you support/oppose? */}
                <div className='debate_choose_section' style={{display: userChoice != null ? 'block' : 'none'}}>
                    <div className='debate_choose_provide_context_text' style={{display: userChoice == 'support' ? 'block' : 'none'}}>Optional : Please provide a logical argument as to why you <span style={{textDecoration: 'underline', color: 'rgb(11, 230, 47)'}}>support</span> this statement.</div>
                    <div className='debate_choose_provide_context_text' style={{display: userChoice == 'oppose' ? 'block' : 'none'}}>Optional : Please provide a logical argument as to why you <span style={{textDecoration: 'underline', color: 'red'}}>oppose</span> this statement.</div>
                    <textarea maxlength="280" className='debate_choose_text_area' ref={myArgumentReference} onChange={()=>updateCharacterCount()} onFocus={()=>handleFocusTextArea(true)} onBlur={()=>handleFocusTextArea(false)}>Start typing here...</textarea>
                    <div className='debate_choose_character_count'>{characterCount}/280</div>

                    <div style={{marginTop: '1.5rem'}} className='common_button common_ack_button' onClick={()=>setArgument()}>Submit</div>
                </div>
            </div>

            {/* My Argument */}
            <div className='debate_choose_myArgument_selected' style={{marginTop: '1rem', display: myArgument.status == 'ok' ? 'block' : 'none'}}>
                <div className='debate_choose_myArgument_selected_title'>My Argument</div>
                <UserArgument argumentContent={myArgument.data} ></UserArgument>
            </div> 

            {/* Chosen Argument */}
            <div className='debate_choose_myArgument_selected' style={{display: chosenArgument.status == 'ok' ? 'block' : 'none'}}>
                <div className='debate_choose_myArgument_selected_title'>Chosen Constituent Argument</div>
                <UserArgument argumentContent={chosenArgument.data} ></UserArgument>
            </div> 


            {/* =============================================================================================================================================== */}         
            {/* List of other peoples arguments */}
            <div style={{display: debateState === 'done' ? 'block' : 'none'}}>
                {
                    memberArguments.map((member) =>

                    <UserArgument argumentContent={member} handleSelection={setChoose}></UserArgument>
                )}
            </div>
            {/* =============================================================================================================================================== */}     


            <l-dot-wave style={{ margin: '0 auto', display: argumentLoad && debateState === 'done' ? 'flex' : 'none'}} size="55" speed=".65" color="black" ></l-dot-wave>
            <div style={{marginTop: '2rem'}}></div>

        </div>
    </div>
    )
}
