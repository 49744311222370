import React, {useState, useEffect, useRef} from 'react'
import './unsubscribe.css'

export default function Unsubscribe({display, closeWindow}) {

    const password = useRef()

    function handleCloseWindow(direction){
        closeWindow(direction, password.current.value)
    }

    return (
    <div className='unsubscribe_section' style={{display: display}}>
        <div className='unsubscribe_are_you_sure'>Are you sure you want to close your account?</div>
        <div className='unsubscribe_note'>Closing your account will remove your personal data, verification states, as well as your subscription. If you complete this submission, you will need to reverify all of your information upon returning to VoterStack.</div>
        
        <div className='unsubscribe_note' style={{marginTop: '30px'}}>Please type in your password to complete the process.</div>
        <input ref={password} type='password' className='common_input' style={{marginBottom: '30px'}}></input>
        
        <div style={{marginRight: '5px'}} className='common_button common_cancel_button' onClick={()=>handleCloseWindow(true)}>Close Account & Sign Out</div>
        <div style={{marginLeft: '5px'}} className='common_button common_ack_button' onClick={()=>handleCloseWindow(false)}>Cancel</div>
    </div>
    )
}
