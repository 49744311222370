import React, {useState, useRef, useEffect} from 'react'
import './officialThread.css'
import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'

export default function OfficialThread({threadInfo, closeThread}) {

    useEffect( () => {
        console.log("Change here")

        if(threadInfo.display === 'block'){
            getOfficialThread(0)
        }
        
        
    }, [threadInfo]);

    
    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    //=============================================================================================

        // SCROLLING RELATED ////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [scrollPosition, setScrollPosition ] = useState(0)
    const scrollReference = useRef()

    function updateScroll(){

        if(scrollReference.current.scrollTop + 1 >= (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)){
            console.log("get more")
            getOfficialThread(skipCount)
        }

        console.log("here")
        setScrollPosition({y: scrollReference.current.scrollTop, yMax: (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)})
    }
    //=============================================================================================

    const [officialResponse, setOfficialResponse] = useState([])


    const [threadLoad, setThreadLoad] = useState(false)
    const [skipCount, setSkipCount] = useState(0)

    async function getOfficialThread(skip){

        if(threadLoad){
            return
        }

        setThreadLoad(true)

        console.log("here")

         // Validate that you can vote
         await API.post('getOfficialsThread', {
            skip: skip,
            officialID: threadInfo.threadInfo.officialID,
            reportID: threadInfo.threadInfo.reportID
        })
        .then(res => {

            console.log(res.data)
            
            setOfficialResponse(prevState => [...prevState, ...res.data.thread]);
            setSkipCount(res.data.skip) 

            setTimeout(() => {
                setThreadLoad(false)
            }, 1000);

        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
            return false
        }) 

    }



    function closeOfficialThreadHandler(){
        setSkipCount(0)
        setOfficialResponse([])
        closeThread()
    }


    return (

    <div style={{display: threadInfo.display}} className='common_overlay'>
        <div className='official_thread_section'>

            <div className='official_thread_scroll_section' onScroll={updateScroll} ref={scrollReference}>
            {
                officialResponse.map((response) => 
                <div>
                    <div className='official_thread_response_section'>
                        
                        <div className='official_thread_name_and_title_grid'>
                            <img src={response.profilePictureURL} className="official_thread_name_and_title_image"></img>
                            <div className='official_thread_name_and_title_container'>
                                <div className='official_thread_name'>{response.name}</div>
                                <div className='official_thread_title'>{response.title}</div>
                            </div>
                            
                        </div>
                        <div className='official_thread_posted_date'>{response.readableDate}</div>

                        <div className='official_thread_response'>{response.response}</div>
                        
                    </div>
                </div>
                
                
            )}


            </div>


            <div className='official_thread_close_button' onClick={()=>closeOfficialThreadHandler()}>Close</div>

            
        </div>
    </div>
  
  )
}
