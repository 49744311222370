import React, {useEffect} from 'react'
import './redirectLogout.css'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"
import TokenService from '../utilities/token.service'

export default function RedirectLogout({trigger}) {
    const navigate = useNavigate()

    // First Render
    useEffect(() => {
        if(trigger){
            
            setTimeout(() => {
                TokenService.removeUser()
                navigate('/')
            }, 5000); 
        }
        
    }, [trigger]);


  return (
    <div style={{display: trigger === true ? 'block' : 'none'}} className='common_overlay'>
        <div className='redirect_logout_section'>
            <div className='redirect_logout_description'>Logging out in 5 seconds... You are being redirected back to the login page.</div>
        </div>
    </div>
  )
}
