import React, {useState, useRef, useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import TokenService from '../utilities/token.service'
import './newCountdown.css'

export default function StacktimeCountdown({expirationDate}) {

    const navigate = useNavigate()

    const [timer, setTimer] = useState({})
    const [radius, setRadius] = useState()


    

    useEffect(() => {
        const interval = setInterval(() => {

            // Current date
            let currentExpiration = new Date(expirationDate)
            let timeRemainingEpoch = currentExpiration.getTime() - new Date().getTime()

            let timeObject = getReturnValues(timeRemainingEpoch)

            if(timeObject.hours <= 0 && timeObject.minutes <= 0 && timeObject.seconds <= 0){
                
                
                TokenService.removeUser()
                navigate("/")
            }

            setTimer(timeObject)

            timeObject = invertRadius(timeObject)

            setRadius({
                hoursRadius: mapNumber(timeObject.hours, 24, 0, 0, 360),
                minutesRadius: mapNumber(timeObject.minutes, 60, 0, 0, 360),
                secondsRadius: mapNumber(timeObject.seconds, 60, 0, 0, 360)
            })

        }, 1000);

        return () => clearInterval(interval);
    }, [timer]);


    return (
        <div className="countdown-wrapper">

            {timer.hours > -1 && (
                <div className="countdown-item">
                    <SVGCircle radius={radius.hoursRadius} />
                    {timer.hours}
                    <span>hours</span>
                </div>
            )}
            {timer.minutes > -1 && (
                <div className="countdown-item">
                    <SVGCircle radius={radius.minutesRadius} />
                    {timer.minutes}
                    <span>minutes</span>
                </div>
            )}
            {timer.seconds > -1 && (
                <div className="countdown-item">
                    <SVGCircle radius={radius.secondsRadius} />
                    {timer.seconds}
                    <span>seconds</span>
                </div>
            )}
        </div>
  )
}

const SVGCircle = ({ radius }) => (
    <svg className="countdown-svg">
        <path
            fill="none"
           
            stroke-width="4"
            d={describeArc(50, 50, 48, 0, radius)}
        />
    </svg>
);

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
    };
}

function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    var d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y
    ].join(' ');

    return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
    return (
        ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
}

const getReturnValues = (countDown) => {
    // calculate time left
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds
    }
  
  };

  function invertRadius(timeObject){
    return {
        hours: 24 - timeObject.hours,
        minutes: 60 - timeObject.minutes,
        seconds: 60 - timeObject.seconds
    }

  }
