import React, {useEffect, useRef, useState} from 'react'
import '../userStatement/userStatement.css'
import './createAdvertisement.css'




export default function CreateAdvertisement({display, type, advertisement, exitCreation, submitCreation}) {

    const textArea = useRef()
    const instructionSection = useRef()
    const autoRenewal = useRef()
    const textBubble = useRef()
    const buttons = useRef()
    const checkboxStatus = useRef()

    const [characterCount, setCharacterCount] = useState(0)

    useEffect(() => {
        
        // Currently closed, open it
        if(display === 'block'){

            // Expand everything
            checkboxStatus.current.checked = false
            buttons.current.className = 'create_advertisement_cancel_submit_container'
            instructionSection.current.className = 'create_advertisement_directions'
            textBubble.current.className = 'create_advertisement_bubble create_advertisement_tri_right btm_left_in create_advertisement_round'
            textArea.current.style.height = textArea.current.clientHeight + '0px'
            instructionSection.current.style.maxHeight = instructionSection.current.scrollHeight + 'px'
            textBubble.current.style.padding = '1rem'
            
        } 
        // Currently open, close it
        else {

            // Minimize and reset everything to zero
            textArea.current.style.height = '0px' 
            textArea.current.value = 'Start typing here...'
            instructionSection.current.style.maxHeight = '0px'
            textBubble.current.style.padding = '0rem'
            checkboxStatus.current.checked = false
            setCharacterCount(0)
        }

    }, [display]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle focus
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function handleFocusTextArea(focused){

        if(textArea.current.value == 'Start typing here...' && focused){
            textArea.current.value = ''
        }

        else if(textArea.current.value == '' && !focused){
            textArea.current.value = 'Start typing here...'
        }
        
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HandleExitCreationHandler
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function handleExitCreationHandler(){
        buttons.current.className = 'fade-out-animation create_advertisement_cancel_submit_container'
        instructionSection.current.className = 'fade-out-animation create_advertisement_directions'
        textBubble.current.className = 'fade-out-animation create_advertisement_bubble create_advertisement_tri_right btm_left_in create_advertisement_round'
        textArea.current.style.height = '0px' 
        instructionSection.current.style.maxHeight = '0px'
        textBubble.current.style.padding = '0rem'
        textArea.current.value = 'Start typing here...'

        setTimeout(() => {
            exitCreation()
        }, 230);  
    }

    function handleAdvertisementSubmission(){
        let myData = {
            statement: textArea.current.value,
            checkmark: checkboxStatus.current.checked,
            type: type,
            registeredPriorityVotes: 0,
            unregisteredPriorityVotes: 0
        }

        submitCreation(myData)
    }

    function updateCharacterCount(){
        let curString = textArea.current.value
        setCharacterCount(curString.length)
    }



  return (
    <div style={{display: display }}>
        <div ref={instructionSection} className='create_advertisement_directions'>
            Please note when you are brainstorming a {type.toLowerCase()} related statement to advertise, you should associate it to the {type.toLowerCase()} scope of government. For example, local governments like your city or township, do not deal with state highway matters. In this case, you will want to make a statement relevant to <span style={{textDecoration: 'underline'}}>{type.toLowerCase()}</span> matters.
            <br></br>
            <br></br>
            <input ref={checkboxStatus} type='checkbox'></input><span style={{color: '#42a4f5'}}> Auto-Renewal </span> Your statement will automatically be readvertised in the next A&S cycle should it not become top priority.
            <br></br><br></br>
            <div style={{color: 'red'}}>Your advertised statement is anonymous</div>
        </div>

        
        <div ref={textBubble} className='create_advertisement_bubble create_advertisement_tri_right btm_left_in create_advertisement_round'>
        
        
            {/* STATEMENT */}
            <textarea ref={textArea} maxlength="280" className='create_advertisement_text_area' onChange={()=>updateCharacterCount()} onFocus={()=>handleFocusTextArea(true)} onBlur={()=>handleFocusTextArea(false)}>Start typing here...</textarea>
            
        </div>

        <div className='create_advertisement_character_count'>{characterCount}/280</div>

        <div ref={buttons} className='create_advertisement_cancel_submit_container'>
            <div className='create_advertisement_button' onClick={()=>handleExitCreationHandler()}>Cancel</div>
            <div></div>
            <div className='create_advertisement_button' onClick={()=>handleAdvertisementSubmission()}>Submit</div>
        </div>
        
        
    </div>
  )
}
