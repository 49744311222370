import React, {useState, useEffect} from 'react'
import './navigation.css'
import { useNavigate, useLocation } from 'react-router-dom';
import TokenService from '../utilities/token.service'

import StacktimeCountdown from '../utilities/stacktimeCountdown'


import mixpanel from 'mixpanel-browser';

export default function Navigation({navigationDisplay, type}) {
  const navigate = useNavigate()
  const location = useLocation();
  let cookies = TokenService.getUser()

  mixpanel.init('1dba164ecdda445989be8b3d0b0271cc');

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [districts, setDistricts] = useState([{districtName: '', districtType: '', districtPhotoURL: ''}])
  const [cycleState, setCycleState] = useState(null)

  const [contentType, setContentType] = useState(type)

  // Set the districts and cycle state
  useEffect(() => {
    setDistricts(cookies.districts)
    setCycleState(cookies.cycle)

  }, []);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////


function selectDistrict(district){

    mixpanel.track("Page Visit", {'Page': district.districtType + " " + cycleState});

    let currentParameters = window.location.search
    let test = currentParameters.split(district.districtType, 2)

    // If chosing the same, reload
    if(test.length === 2){
      window.location.reload()
    }

    setContentType(district.districtType)
    
    if(cycleState === 'advertiseSelect'){
      navigate('/advertiseSelect?type=' + district.districtType + '&filter=HCPV')
    } else {
      navigate('/debateChoose?type=' + district.districtType + '&filter=HCV')
    }

  }

  function selectNavigation(where){

    // Choosing the same, reload
    if(where === location.pathname){
      window.location.reload()
    }

    switch(where){
      case "/activityHistory":
        mixpanel.track("Page Visit", {'Page': "Activity History"});
        navigate(where)
      break
      case "/officialResponses":
        navigate(where)
      break
      case "/myProfile":
        mixpanel.track("Page Visit", {'Page': "My Profile"});
        navigate(where)
      break
      case "/feedbackSupport":
        mixpanel.track("Page Visit", {'Page': "Feeback & Support"});
        navigate(where)
      break
      case "/userSettings":
        mixpanel.track("Page Visit", {'Page': "Settings"});
        navigate(where)
      break
    }

  }

  function signOut(){
    TokenService.removeUser()
    navigate("/")
  } 

  return (
    <div style={{display: navigationDisplay}} className="navigation_screen_foreground">
      {/* Main Activities */}
      <div className="navigation_section">
        <div className='navigation_special_section_title'>{cycleState === 'advertiseSelect' ? 'Advertise & Selection - In Progress' : 'Debate & Choose - In Progress'} </div>
        <div className='navigation_special_section_subtitle'>{cycleState === 'advertiseSelect' ? 'Debate & Choose ' : 'Advertise & Selection '} will start in:</div>
            <StacktimeCountdown expirationDate={cookies.sessionExpire}></StacktimeCountdown>
            {/*<Countdown timeTillDate={cookies.sessionExpire} timeFormat="YYYY-MM-DDTHH:mm:ss.SSSSZ" />*/}
            <div style={{height: '35px'}}></div> 
        <div className='navigation_special_section_instruction'>
          {cycleState === 'advertiseSelect' ? 
            'Please select one of your districts in order to participate in the Advertisement & Selection process. In this session, you will advertise a statement which may be elected by your fellow constituents as a top priority issue or idea. In addition, you will select a single statement originating from a fellow constituent of your district to determine a common top priority.' 
            : 
            'Please select one of your districts in order to participate in the Debate & Choose process. In this session, you will provide your stance on the elected top priority while giving your best supporting or opposing argument. In addition, you will choose a single argument from a fellow constituent of your district which closely resonates with your views on the subject.'}
            </div>
            {
              districts.map((district) =>
                <div>
                  <div className={district.districtType == contentType ? "navigation_district_grid navigation_district_grid_highlight" : "navigation_district_grid"} onClick={()=>selectDistrict(district)}>
                      <img className='navigation_element_icon' src={district.districtPhotoURL}></img>
                      
                      <div style={{display: district.districtType === 'City' ? 'block' : 'none'}} className='navigation_district_grid_text'>City of {district.districtName}</div>
                      <div style={{display: district.districtType === 'Township' ? 'block' : 'none'}} className='navigation_district_grid_text'>{district.districtName} Township</div>
                      <div style={{display: district.districtType === 'County' ? 'block' : 'none'}} className='navigation_district_grid_text'>{district.districtName} County</div>
                      <div style={{display: district.districtType === 'State' ? 'block' : 'none'}} className='navigation_district_grid_text'>State District {district.districtName}</div>
                      <div style={{display: district.districtType === 'Federal' ? 'block' : 'none'}} className='navigation_district_grid_text'>Federal District {district.districtName}</div>
                  </div>
                </div>
            )}
      </div>

      {/* Profile */}
      <div className="navigation_section">


        <div className={location.pathname === '/officialResponses' ? "navigation_option navigation_option_highlight" : "navigation_option"} onClick={()=>selectNavigation("/officialResponses")}>Official Responses</div>
        {/*<div className='navigation_title'>Profile</div> */}


        <div className={location.pathname === '/myProfile' ? "navigation_option navigation_option_highlight" : "navigation_option"} onClick={()=>selectNavigation("/myProfile")}>Profile</div>
        <div className={location.pathname === '/userSettings' ? "navigation_option navigation_option_highlight" : "navigation_option"} onClick={()=>selectNavigation("/userSettings")}>Settings</div>
        <div className={location.pathname === '/activityHistory' ? "navigation_option navigation_option_highlight" : "navigation_option"} onClick={()=>selectNavigation("/activityHistory")}>Activity History</div>
        <div className={location.pathname === '/feedbackSupport' ? "navigation_option navigation_option_highlight" : "navigation_option"} onClick={()=>selectNavigation("/feedbackSupport")}>Feedback & Support</div>
        

        <div className="navigation_option" style={{color: 'red'}} onClick={()=> signOut()}>Sign Out</div>
  
      </div>


       


    </div>
  )
}
