module.exports = function parse(text, matches) {
    const result = [];
  
    if (matches.length === 0) {
      result.push({
        text,
        highlight: false
      });
    } else if (matches[0][0] > 0) {
      result.push({
        text: text.slice(0, matches[0][0]),
        highlight: false
      });
    }
  
    matches.forEach((match, i) => {
      const startIndex = match[0];
      const endIndex = match[1];
  
      result.push({
        text: text.slice(startIndex, endIndex),
        highlight: true
      });
  
      if (i === matches.length - 1) {
        if (endIndex < text.length) {
          result.push({
            text: text.slice(endIndex, text.length),
            highlight: false
          });
        }
      } else if (endIndex < matches[i + 1][0]) {
        result.push({
          text: text.slice(endIndex, matches[i + 1][0]),
          highlight: false
        });
      }
    });
  
    return result;
  };