import React, {useEffect, useState, useRef} from 'react'
import {
  Routes,
  Route,
  useSearchParams,
  BrowserRouter,
  useNavigate
} from "react-router-dom"
import './successfulTransaction.css'
import ErrorWindow from '../../errorWindow/errorWindow'
import VerificationIcon from '../../images/verificationIcon.png'
import success from '../../images/success.png'
import ProgressBar from '../../progressBar/progressBar'

export default function SuccessfulTransaction() {
  var baseAddress = null

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    baseAddress = 'https://voterstack.com:5016/'
  } else {
    // production code
    baseAddress = 'https://voterstack.com:5015/'
  }
  
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
    getSignUpCode()
  }, [])

  // Error Related
  const [errorDisplay, setErrorDisplay] = useState('none')
  const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

  function closeError(){
      setErrorDisplay('none')
  }

  const [queryParameters] = useSearchParams()
  const verificationCode = useRef()

  const [phoneVerified, setPhoneVerified] = useState(false)

  /////////////////////////////////////////////////////////////////////
  // Get Sign Up Code
  /////////////////////////////////////////////////////////////////////
  function getSignUpCode(){

    fetch(baseAddress + "getSignUpCode", {
      method: "POST",
      headers: {
      "content-type" : 'application/json'
      },
      body: JSON.stringify({
          trackerID: queryParameters.get("trackerID")
      })
    })
    .then(res => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    .then(res => {


    })
    .catch(error => {
        setErrorDetails(error)
        setErrorDisplay('block')
    })
  }

  /////////////////////////////////////////////////////////////////////
  // Send Code
  /////////////////////////////////////////////////////////////////////
  function sendSignUpCode(){

    fetch(baseAddress + "verifySignUpCode", {
      method: "POST",
      headers: {
      "content-type" : 'application/json'
      },
      body: JSON.stringify({
          trackerID: queryParameters.get("trackerID"),
          code: verificationCode.current.value
      })
    })
    .then(res => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    .then(res => {
      setPhoneVerified(true)

    })
    .catch(error => {
        setErrorDetails(error)
        setErrorDisplay('block')
    })
  }

  /////////////////////////////////////////////////////////////////////
  // ReturnHome
  /////////////////////////////////////////////////////////////////////
  function returnHome(){
    navigate('/')
  }



  return (
    <div className="screen_background">
      <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
      <div className="signup_screen_foreground">
          <div className="signup_outer_section">
              <div className="signup_inner_section">

                <div style={{display: phoneVerified ? 'none' : 'block'}}>
                  <img className='common_icon_center' src={VerificationIcon}></img>
                  <div className='common_title_subtext'>Finally, let's validate your phone number!</div>
                  <ProgressBar page='phoneVerification'></ProgressBar>
                  <div className='common_note'>A text message should be arriving to your phone with a code which you will need to enter in the field below to complete your sign up.</div>
                  <div className='successful_transaction_code_resend' onClick={()=>getSignUpCode()}>Didn't receive code? Click here to try resending</div>
                  <div className='common_input_text' style={{textAlign: 'center', marginBottom: '1rem'}}>Verification Code</div>
                  <input ref={verificationCode} type='password' className='common_input'></input>
                  
                  <div className='common_button common_submit_button' onClick={()=>sendSignUpCode()}>Verify</div>
                </div>

                <div style={{display: phoneVerified ? 'block' : 'none'}}>
                  <img className='common_icon_center' src={success}></img>
                  <div className='common_title_subtext'>Congratulations! You're finished!</div>
                  <div className='common_note'>Thank you for providing us with this information. Your information will now be reviewed by a VoterStack representative. This process will be completed in less than 24 hours from this submission. Once the review is completed, you will receive a text message and be notified of your approval. We appreciate your patience!</div>
                  <div className='common_note' style={{marginTop: '1rem'}}>If you have any questions or experience any issues, please contact support!</div>
                  <div className='common_button common_submit_button' onClick={()=>returnHome()}>Return Home</div>
                </div>

              </div>
          </div>
      </div>
    </div>
  )
}
