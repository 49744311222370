import React, {useEffect} from 'react'
import './argumentFilter.css'

export default function ArgumentFilter({display, filter, closeWindow}) {

  function selectionHandler(selection){
    closeWindow(selection)
  }



  return (
    <div style={{display: display}} className='argument_filter_overlay'>
      <div className='argument_filter_options_center'>
        <div className='argument_filter_title' style={{marginBottom: '3rem'}}>Filter Results</div>
        <div className={filter == "HCV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('HCV')}>Highest Cumulative Votes</div>
        <div className={filter == "LCV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('LCV')}>Lowest Cumulative Votes</div>
        <div className={filter == "HRAV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('HRAV')}>Highest Registered Agree Votes</div>
        <div className={filter == "LRAV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('LRAV')}>Lowest Registered Agree Votes</div>
        <div className={filter == "HRDV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('HRDV')}>Highest Registered Disagree Votes</div>
        <div className={filter == "LRDV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('LRDV')}>Lowest Registered Disagree Votes</div>
        <div className={filter == "HUAV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('HUAV')}>Highest Unregistered Agree Votes</div>
        <div className={filter == "LUAV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('LUAV')}>Lowest Unregistered Agree Votes</div>
        <div className={filter == "HUDV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('HUDV')}>Highest Unregistered Disagree Votes</div>
        <div className={filter == "LUDV" ? 'argument_filter_highlight' : 'argument_filter_element'} onClick={()=>selectionHandler('LUDV')}>Lowest Unregistered Disagree Votes</div>

        <div className='argument_filter_element argument_filter_element_cancel' onClick={()=>selectionHandler('cancel')}>Cancel</div>
      </div>

    </div>
  )
}
