import React, {useEffect, useState, useRef} from 'react'
import ErrorWindow from '../../errorWindow/errorWindow'
import DriversLicense from '../../images/driversLicense.png'
import ExampleID from '../../images/exampleID.jpg'
import './identityProof.css'
import ProgressBar from '../../progressBar/progressBar'

export default function IdentityProof({display, setIdentity, slide}) {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    // Image selection
    const hiddenFileInput = React.useRef(null);

    const handleClickRedirect = event => {
        hiddenFileInput.current.click();
    };

    let [ID, setID] = useState()
    let [IDPreview, setIDPreview] = useState()
    let [isFile, setIsFile] = useState(false)

    //////////////////////////////////////////////////////////////
    // handleFileCollect
    //////////////////////////////////////////////////////////////
    function handleIDCollect(){
        setIsFile(true)
        setID(hiddenFileInput.current.files[0])
        setIDPreview(URL.createObjectURL(hiddenFileInput.current.files[0]))
    }

    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('identityProof', direction)
    }

    //////////////////////////////////////////////////////////////
    // addIDPhoto
    //////////////////////////////////////////////////////////////
    function addIDPhoto(){

        if(!isFile){
            setErrorDetails({title: "ID Photo missing", description: "Please use the upload button to upload your photo ID."})
            setErrorDisplay('block')
            setIsFile(false)
            return
        }

        setIdentity(hiddenFileInput.current.files[0])
        slide('identityProof', true)
    }

    return (
        <div style={{display: display ? 'block' : 'none'}}>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
            <img className='common_icon_center' src={DriversLicense}></img>
            <div className='common_title_subtext'>Now let's validate your identity!</div>
            <ProgressBar page='verifyIdentity'></ProgressBar>
            <div className='common_note'>In order to validate your identity as a constituent for your districts, you will need to provide a valid drivers license or state ID which proves your information.</div>

            <div className='common_note' style={{marginTop: '1rem'}}>Please capture and upload a clear photo of the front of your state issued ID with the corners included. Once you have finished selecting, please continue to mailing address validation.</div>
            
            {/* Image preview */}
            <img style={{display: isFile ? "block" : "none"}}  className='identity_proof_id_preview' src={IDPreview} />


            {/* Select image */}
            <div onClick={handleClickRedirect} className='common_button common_submit_button' style={{marginTop: '2rem', marginBottom: '2rem'}}>
            {isFile ? "Change ID Photo" : "Upload ID Photo"}
            </div>
            <input type="file"
             ref={hiddenFileInput}
             onChange={()=>handleIDCollect()}
             style={{display:'none'}}
            /> 


            <div className='common_button_combination' >
                <div style={{marginRight: '5px'}} className='common_button common_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>
                <div style={{marginLeft: '5px'}} className='common_button common_submit_button' onClick={()=>addIDPhoto()}>Continue</div>
            </div>
        </div>
    )
}
