import React from 'react'
import './progressBar.css'

export default function ProgressBar({page}) {
  return (
    <div>
        <div className='progress_bar_flex'>
            <div className={page === 'addressValidate' ? 'progress_bar_element progress_bar_highlight' : 'progress_bar_element_hidden'}>
                {page === 'addressValidate' ? 'Coverage Validation' : ''}
            </div>
            <div className={page === 'reviewOfficials' ? 'progress_bar_element progress_bar_highlight' : 'progress_bar_element_hidden'}>
                {page === 'reviewOfficials' ? 'Review Officials' : ''}
            </div>

            <div className={page === 'basicInformation' ? 'progress_bar_element progress_bar_highlight' : 'progress_bar_element_hidden'}>
                {page === 'basicInformation' ? 'Basic Information' : ''}
            </div>

            <div className={page === 'verifyIdentity' ? 'progress_bar_element progress_bar_highlight' : 'progress_bar_element_hidden'}>
                {page === 'verifyIdentity' ? 'Verify Identity' : ''}
            </div>

            <div className={page === 'verifyLocation' ? 'progress_bar_element progress_bar_highlight' : 'progress_bar_element_hidden'}>
                {page === 'verifyLocation' ? 'Verify Location' : ''}
            </div>

            <div className={page === 'reviewInformation' ? 'progress_bar_element progress_bar_highlight' : 'progress_bar_element_hidden'}>
                {page === 'reviewInformation' ? 'Review Information' : ''}
            </div>

            <div className={page === 'payment' ? 'progress_bar_element progress_bar_highlight' : 'progress_bar_element_hidden'}>
                {page === 'payment' ? 'Payment' : ''}
            </div>

            <div className={page === 'phoneVerification' ? 'progress_bar_element progress_bar_highlight' : 'progress_bar_element_hidden'}>
                {page === 'phoneVerification' ? 'Phone Verification' : ''}
            </div>

        </div>

    </div>
  )
}
