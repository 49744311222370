import React, {useState, useEffect, useRef} from 'react'
import './voterRegistration.css'
import API from "../../utilities/api"
import ErrorWindow from '../../errorWindow/errorWindow'

export default function VoterRegistration({display, closeWindow}) {

    useEffect(()=>{

        if(display === 'block'){
            getMyAddressInformation()
        }
        
    }, [display])


    const [addressObject, setAddressObject] = useState('none')




    ////////////////////////////////////////////////////////////////////////////////////////////////
    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // CUR REQUESTS  ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    async function getMyAddressInformation(){
      
        await API.post('getMyInformation', {
            informationType: 'address'
        })
        .then(res => {
            setAddressObject(res.data)
        })
        .catch(err => { 
            console.log(err)
            setErrorDetails(err)
            setErrorDisplay('block')
            return false
        }) 
    }
  
    /////////////////////////////////////////////////////////////////////////////////////////////


    function handleCloseWindow(direction){
        closeWindow(direction)
    }

    return (
        <div className='voter_registration_section' style={{display: display}}>
            
            <div className='voter_registration_title' style={{fontSize: '14pt'}}>Voter Registration Verification Request</div>

            <div className='voter_registration_notice'>Note: Please validate to make sure that you have fully completed your voter registration through your states official process. This process can be found on your states website:</div>
            
            <a className='voter_registration_state_links' href='https://www.sos.state.mn.us/elections-voting/register-to-vote/' target='_blank'>- Minnesota Secretarty Of State - Click here to register</a>
            

            <div className='voter_registration_notice'>Once you have completed your voter registration and waited the allotted processing time, you will want to validate your registration status. You will find it easier to complete self validation of your registration status before submission. This process can be found via <span><a href='https://www.vote.org/am-i-registered-to-vote/' target='_blank'>www.vote.org</a></span></div>
            
            
            <div className='voter_registration_title'>You wish make the request to validate your voter registration status for the following address:</div>
            
            <div className='voter_registration_address_box'>
                <div>{addressObject.addressLineOne}</div>
                <div>{addressObject.addressLineTwo}</div>
                <div>{addressObject.city}</div>
                <div>{addressObject.state}</div>
                <div>{addressObject.zipCode}</div>
            </div>

            <div className='common_button common_submit_button' onClick={()=>handleCloseWindow(true)}>Yes, Register Me Now</div>
            <div className='common_button common_ack_button' onClick={()=>handleCloseWindow(false)}>Cancel</div>
        </div>
    )
}
