import React, {useState, useRef} from 'react'
import API from "../../utilities/api"
import ErrorWindow from '../../errorWindow/errorWindow'
import '../passwordReset.css'



export default function ProvideProof({progressionState, advanceOrWithdraw, setData}) {
    const [disableButton, setDisableButton] = useState(false)

    const phoneNumber = useRef()
    const lastName = useRef()
    const zipCode = useRef()
    const password = useRef()
    const confirmPassword = useRef()

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
          


    async function continueToNextScreen(){

      let data = {
        phoneNumber: phoneNumber.current.value,
        lastName: lastName.current.value,
        zipCode: zipCode.current.value,
        password: password.current.value,
        confirmPassword: confirmPassword.current.value
      }

      await API.post('resetPasswordValidate', data)
      .then(res => {
          setData(data)
          advanceOrWithdraw('provideProof', true)

          // move to next screen
      })
      .catch(err => { 
          setErrorDetails(err)
          setErrorDisplay('block')
      })

    }

  return (
    <div style={{display : progressionState == 'provideProof' ? 'block' : 'none', marginTop: "20px"}}>
      <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
      <div className='form_box'>
        <form action="#">
          <div className="password_reset_input_box">
              <input ref={phoneNumber} type="text" placeholder="Phone Number" required/>
              <div className="password_reset_icon"><i className="fa fa-phone"></i></div>
          </div>
          <div className="password_reset_input_box">
              <input ref={lastName} type="text" placeholder="Last Name" required/>
              <div className="password_reset_icon"><i className="fa fa-user"></i></div>
          </div>
          <div className="password_reset_input_box">
              <input ref={zipCode} type="text" placeholder="Zip Code" required/>
              <div className="password_reset_icon"><i className="fa fa-map-marker"></i></div>
          </div>
          <div className="password_reset_input_box">
              <input ref={password} type="password" placeholder="New Password" required/>
              <div className="password_reset_icon"><i className="fa fa-lock"></i></div>
          </div>
          <div className="password_reset_input_box">
              <input ref={confirmPassword} type="password" placeholder="Confirm New Password" required/>
              <div className="password_reset_icon"><i className="fa fa-lock"></i></div>
          </div>
        </form>
      </div>

        <div className='common_button_combination' style={{marginTop: '20px'}}>
            <div style={{marginRight: '5px'}} className='common_button common_cancel_button' isDisabled={disableButton} onClick={()=>advanceOrWithdraw('provideProof',false)}>Back</div>
            <div style={{marginLeft: '5px'}} className='common_button common_submit_button' isDisabled={disableButton} onClick={()=>continueToNextScreen()}>Next</div>
        </div>
    </div>
  )
}
