import React from 'react'
import './failedTransaction.css'

export default function FailedTransaction() {
  return (
    <div>
        <div>Failed!</div>
    </div>
  )
}
