import React, {useState} from 'react'
import './metricType.css'
import MetricDescription from '../metricDescription/metricDescription'

export default function MetricType({type, count}) {

    // Error Related
    const [metricDescriptionDisplay, setMetricDescriptionDisplay] = useState('none')

    function closeMetricDescription(){
        setMetricDescriptionDisplay('none')
    }

    function showMetricInformation(){
        setMetricDescriptionDisplay('block')
    }


    return (
        <div >
            <MetricDescription display={metricDescriptionDisplay} count={count} type={type} closeDescription={closeMetricDescription}></MetricDescription>
            <div className='metric_type_bar' onClick={()=>showMetricInformation()}>
                <div className={'metric_type_label ' + type}>&#9432; {type}</div>
                <div className='metric_type_count'>{count}</div>
            </div>
        </div>
    )
}
