import React, {useRef, useEffect, useState} from 'react'
import './advertiseSelect.css'
import { dotWave } from 'ldrs'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"

import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import Header from '../header/header'
import Navigation from '../navigation/navigation'
import MetricType from '../metricType/metricType'
import UserStatement from '../userStatement/userStatement'
import MyAdvertisement from '../myAdvertisement/myAdvertisement'
import ConfirmSubmission from '../confirmSubmission/confirmSubmission'
import SuccessSubmission from '../successSubmission/successSubmission'
import AdvertisementFilter from '../advertisementFilter/advertisementFilter'

import mixpanel from 'mixpanel-browser';

import Select from 'react-select'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    var websiteURL = "https://voterstack.com:3000/advertiseSelect?"
} else {
    // production code
    var websiteURL = "https://voterstack.com/advertiseSelect?"
}





export default function AdvertiseSelect() {

    mixpanel.init('1dba164ecdda445989be8b3d0b0271cc');

    console.log(process.env.NODE_ENV)

    dotWave.register()

    const navigate = useNavigate()

    const [queryParameters] = useSearchParams()


    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    //=============================================================================================

    // NAVIGATION RELATED ///////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [pageContentsDisplay, setPageContentsDisplay] = useState('block')
    const [navigationDisplay, setNavigationDisplay] = useState('none')

    function hidePage(){
        setPageContentsDisplay('none')
        setNavigationDisplay('block')
    }

    function displayPage(){
        setPageContentsDisplay('block')
        setNavigationDisplay('none')
    }
    //=============================================================================================

    // SCROLLING RELATED ////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [scrollPosition, setScrollPosition ] = useState(0)
    const scrollReference = useRef()

    function updateScroll(){

        if(scrollReference.current.scrollTop + 1 >= (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)){
            getConstituentAdvertisements(queryParameters.get("type"), queryParameters.get("filter"), skipCount)
        }

        setScrollPosition({y: scrollReference.current.scrollTop, yMax: (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)})
    }
    //=============================================================================================

    // THIS PAGE RELATED ////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [skipCount, setSkipCount] = useState(0)
    const [advertisementLoad, setAdvertisementLoad] = useState(false)
    const [memberAdvertisements, setMemberAdvertisements] = useState([])

    const [selectionStatus, setSelectionStatus] = useState('open')
    const [selectedAdvertisement, setSelectedAdvertisement] = useState({})
    const [selectedAdvertisementID, setSelectedAdvertisementID] = useState(null)

    const [filterDisplay, setFilterDisplay] = useState('none')


    const [priorityLoad, setPriorityLoad] = useState(false)

    const [myAdvertisement, setMyAdvertisement] = useState({statement: null, postedDate: null, registeredPriorityVotes: null, unregisteredPriorityVotes: null})
    //=============================================================================================


    // First Render
    useEffect(() => {

        resetAll()
        getConstituentAdvertisements(queryParameters.get("type"), queryParameters.get("filter"), 0)
        getMyPriority()

    }, [queryParameters.get("filter"), queryParameters.get("type")]);


    // ResetAll
    function resetAll(){
        setMemberAdvertisements([]);
        setSelectionStatus('open')
        setSelectedAdvertisementID(null)
        setSelectedAdvertisement({})
        displayPage()
        setFilterDisplay('none')
        window.scrollTo(0, 0)
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Load advertisements
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getConstituentAdvertisements(type, filter, skip){

        if(advertisementLoad){
            return
        }

        setAdvertisementLoad(true)

        let advertisementRequest = {
            skipCount: skip,
            type: type,
            filter: filter
        }

        await API.post('getConstituentAdvertisements', advertisementRequest)
        .then(res => {

            setMemberAdvertisements(prevState => [...prevState, ...res.data.advertisements]);

            setSkipCount(res.data.skipCount)
            
            setTimeout(() => {
                setAdvertisementLoad(false)
            }, 1000);
        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
        })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Get My Priority
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getMyPriority(){

        await API.post('getMyPriority', {
            type: queryParameters.get("type")
        })
        .then(res => {

            if(res.data.status === 'done'){
                setSelectedAdvertisement(res.data)
                setSelectionStatus(res.data.status)
            } else {
                setSelectedAdvertisement({})
                setSelectionStatus('open')
            }
        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
        })
    }
    

    function setSelectionPromptConfirm(id){
        setSelectionStatus('pending')
        setSelectedAdvertisementID(id)

    }



    async function advanceOrWithdraw(direction){

        console.log(direction)

        if(direction === 'advance'){

            console.log(priorityLoad)

            if(priorityLoad){
                return
            }
            setPriorityLoad(true)
     
            // Validate that you can vote
            await API.post('setAsPriority', {
                statementID: selectedAdvertisementID,
                type: queryParameters.get("type")
            })
            .then(res => {

                setPriorityLoad(false)

                mixpanel.track("Selected Priority");
                
                let newArray = []

                // Cycle through each iteration and remove the voting button
                for(let i = 0; i < memberAdvertisements.length; i++){

                    if(memberAdvertisements[i]._id === selectedAdvertisementID){
                        if(res.data.registeredVoter){
                            memberAdvertisements[i].registeredPriorityVotes = res.data.updatedVotecount
                        } else {
                            memberAdvertisements[i].unregisteredPriorityVotes = res.data.updatedVotecount
                        }
                        memberAdvertisements[i].available = false
                        setSelectedAdvertisement(memberAdvertisements[i])
                    } else {
                        memberAdvertisements[i].available = false
                        newArray.push(memberAdvertisements[i])
                    }
                }

                setMemberAdvertisements(newArray) 
                setSelectionStatus('success')
            })
            .catch(err => { 
                setErrorDetails(err)
                setErrorDisplay('block')
                return false
            }) 
        } else {
            // Clear the contents !!!
            setSelectionStatus('open')
            setSelectedAdvertisementID(null)
            setSelectedAdvertisement({})
        }
    }

    function closeSuccess(){
        setSelectionStatus('done')
    }


    

    function openFilterWindow(){
        setFilterDisplay('block')
    }

    function closeFilterWindow(localFilter){
        
        if(localFilter === 'cancel'){
            setFilterDisplay('none')
            return
        }

        // Update the webpage
        navigate('/advertiseSelect?type=' + queryParameters.get("type") + '&filter=' + localFilter)
    }


    let submitVote = 'You will only be able to vote on a single selection during this A&S session. Please be sure before submission.'
    let successMessage = 'Thank you! Your selection has been successfully submitted!'

    return (
    <div className='screen_background' onScroll={updateScroll} ref={scrollReference}>
        <Header hideFunction={hidePage} openFunction={displayPage} navDisplay={navigationDisplay}></Header>
        <Navigation navigationDisplay={navigationDisplay} type={queryParameters.get("type")}></Navigation>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        <ConfirmSubmission display={selectionStatus == 'pending' ? true : false} type={queryParameters.get("type")} message={submitVote} close={advanceOrWithdraw}></ConfirmSubmission>
        <SuccessSubmission display={selectionStatus == 'success' ? true : false} type={queryParameters.get("type")} message={successMessage} close={closeSuccess}></SuccessSubmission>
        <AdvertisementFilter display={filterDisplay} filter={queryParameters.get("filter")} closeWindow={closeFilterWindow}></AdvertisementFilter>
        <div style={{display: pageContentsDisplay}} className='advertise_select_screen_foreground' >
            
            {/* Filter */}
            <div className='advertise_select_filter_container_bar'>
                <div></div>
                <div>
                    <i class="fa fa-filter advertise_select_filter_button" onClick={()=>openFilterWindow()}></i>
                </div>
            </div> 
            

            {/* Container */}
            <div style={{boxSizing: 'borderBox', marginLeft: '8px', marginRight: '8px', maxWidth: '800px'}} >

                {/* My Advertisement */}
                <MyAdvertisement type={queryParameters.get("type")}></MyAdvertisement>

                {/* My Selection */}
                <div style={{display: selectionStatus == 'done' ? 'block' : 'none'}} className='advertise_select_chosen_container fade-in-left'>
                    <div className='advertise_select_chosen_title'>My Prioritized {queryParameters.get("type")} Selection</div>
                    <UserStatement statementContent={selectedAdvertisement} type={queryParameters.get("type")} session={null} handleSelection={null}></UserStatement>
                </div>


                {/*
                <div style={{fontSize: '20pt', color: 'white'}}>{scrollPosition.y}</div>
                <div style={{fontSize: '20pt', color: 'white'}}>{scrollPosition.yMax}</div> 
                */}

                {/* Constituent Advertisements */}
                <div>
                {
                    memberAdvertisements.map((member) => 
                        <div key={member._id}>
                            <div style={{marginTop: '1rem'}}></div>
                            <UserStatement statementContent={member} type={queryParameters.get("type")} session={'advertiseSelect'} handleSelection={setSelectionPromptConfirm}></UserStatement>

                        </div>  
                )}
                </div>
                <l-dot-wave style={{margin: '0 auto', display: advertisementLoad ? 'block' : 'none'}} size="55" speed=".65" color="black" ></l-dot-wave>
                <div style={{marginTop: '2rem'}}></div>
        </div>



        </div>
    </div>
    )
}
