import React from 'react'
import './userArgument.css'
import MetricType from '../metricType/metricType'


export default function UserArgument({argumentContent, handleSelection}) {

    
    function selectionHandler(id){
        handleSelection(id)
    }




  return (
    <div className="fade-in-left">
        <div className='user_argument_bubble round'>
            {/* <div className='user_argument_anonymous'>Anonymous {type} Constituent writes:</div> */}
            {/* DATE CREATED */}

           
            {/* argument */}
          

                    <div className='user_argument_flex'>
                        <div className={argumentContent.position == 'support' ? 'user_argument_position user_argument_supporter' : 'user_argument_position user_argument_opposer'}>{argumentContent.position == 'support' ? "Supporter" : "Opposer"}</div>
                        <div className='user_argument_creation_text'>{argumentContent.readableDate}</div>
                    </div>

               
            
            <div className='user_argument_statement'>{argumentContent.argument}</div>
            

            {/* METRICS */}
            <div className='metric_columns'>
                <MetricType type={'RAV'} count={argumentContent.registeredUpvotes}></MetricType>
                <div></div>
                <MetricType type={'UAV'} count={argumentContent.unregisteredUpvotes}></MetricType>
            </div>

            <div className='user_argument_grid'>
                <div></div>
                <div style={{display: 'block', float: 'right'}}>
                    <div style={{display: argumentContent.available ? 'block' : 'none', marginTop: '.75rem', padding: '.15rem'}} className='common_button common_ack_button' onClick={()=>selectionHandler(argumentContent._id)}>Give Supporting Vote <span style={{color: 'green'}}>&#10003;</span></div>
                </div>
            </div>
        </div>
    </div>
  )
}
